// theme.scss

@import '~@angular/material/theming';
/* For use in src/lib/core/theming/_palette.scss */
$md-rajguruprimary: (
    50 : #e0f2e7,
    100 : #b3dec2,
    200 : #80c89a,
    300 : #4db172,
    400 : #26a153,
    500 : #009035,
    600 : #b1c900,
    700 : #f1f2dd,
    800 : #007322,
    900 : #006116,
    A100 : #91ffa0,
    A200 : #5eff74,
    A400 : #2bff48,
    A700 : #12ff32,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
/* For use in src/lib/core/theming/_palette.scss */
$md-rajgurusecondary: (
    50 : #fafbfb,
    100 : #f4f4f4,
    200 : #eceded,
    300 : #e4e5e6,
    400 : #dfe0e0,
    500 : #d9dadb,
    600 : #d7b369,
    700 : #cfd0d2,
    800 : #cacbcd,
    900 : #c0c2c4,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-rajgurutertiary: (
    50 : #fce0e3,
    100 : #f7b3b9,
    200 : #f1808b,
    300 : #eb4d5c,
    400 : #e72639,
    500 : #e30016,
    600 : #e00013,
    700 : #dc0010,
    800 : #d8000c,
    900 : #d00006,
    A100 : #fff8f8,
    A200 : #ffc5c6,
    A400 : #ff9293,
    A700 : #ff7979,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
// Define your custom colors
$primary: mat-palette($md-rajguruprimary,500,600,700); // #009035
$accent: mat-palette($md-rajgurusecondary, 800, 500, 900); // #D7B369
$warn: mat-palette($md-rajgurutertiary, 500); // #E30016

// Create the theme
$theme: mat-light-theme($primary, $accent, $warn);

// Include the Angular Material theme styles
@include mat-core();
@include angular-material-theme($theme);

// Add custom styles here if needed
